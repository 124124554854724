<template>
  <div class="yl-input" :class="disabled ? 'disabled' : ''">
    <div class="icon">
      <slot></slot>
    </div>
    <input
      :class="disabled ? 'disabled' : ''"
      :type="type"
      v-model="data"
      :placeholder="placeholder"
      :disabled="disabled"
      autocomplete="new-password"
      :maxlength="code ? 6 : ''"
    />
    <button
      v-if="code"
      @click="sendCode()"
      :class="['code-btn', send ? 'active' : '']"
    >
      {{ send ? time + "S后重新获取" : "发送验证码" }}
    </button>
    <span v-else-if="forget" class="forget" @click="toRetrieve()"
      >忘记密码？</span
    >
  </div>
</template>
<script>
import { code, emailCode, findCode } from "../api/user";
export default {
  props: {
    value: String,
    prefixIcon: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    code: {
      type: Boolean,
      default: false,
    },
    codeType: {
      type: String,
      default: "0",
    },
    phone: String,
    email: String,
    verify: {
      type: String,
      default: "phone",
    },
    forget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      send: false,
      time: 0,
      timer: null,
      phoneReg: /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^6\d{5}$/,
      emailReg:
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
      codeFlag: true,
    };
  },
  watch: {
    value(newVal) {
      this.data = newVal;
    },
    data(newVal) {
      this.$emit("input", newVal);
    },
  },
  methods: {
    sendCode() {
      if (this.verify == "phone") {
        if (this.phone) {
          if (!this.phoneReg.test(this.phone)) {
            this.$ylmessage.error("请输入正确的手机号");
          } else if (this.codeFlag) {
            this.codeFlag = false;
            if (this.codeType == 0) {
              code({
                phone: this.phone,
              })
                .then((res) => {
                  if (res.code == 0) {
                    this.time = 60;
                    this.send = true;
                    this.timer = setInterval(() => {
                      if (this.time <= 0) {
                        this.send = false;
                        this.codeFlag = true;
                        clearInterval(this.timer);
                      } else {
                        this.time--;
                      }
                    }, 1000);
                  } else {
                    this.codeFlag = true;
                  }
                })
                .catch(() => {
                  this.codeFlag = true;
                });
            } else if (this.codeType == 1) {
              // 找回密码验证码
              findCode({
                phone: this.phone,
              })
                .then((res) => {
                  if (res.code == 0) {
                    this.time = 60;
                    this.send = true;
                    this.timer = setInterval(() => {
                      if (this.time <= 0) {
                        this.send = false;
                        this.codeFlag = true;
                        clearInterval(this.timer);
                      } else {
                        this.time--;
                      }
                    }, 1000);
                  } else {
                    this.codeFlag = true;
                    this.$ylmessage.error(res.msg);
                  }
                })
                .catch(() => {
                  this.codeFlag = true;
                });
            }
          }
        } else {
          this.$ylmessage.error("请输入手机号");
        }
      } else if (this.verify == "email") {
        if (this.email) {
          if (!this.emailReg.test(this.email)) {
            this.$ylmessage.error("请输入正确的邮箱");
          } else if (this.codeFlag) {
            this.codeFlag = false;
            emailCode({
              email: this.email,
            })
              .then((res) => {
                if (res.code == 0) {
                  this.time = 60;
                  this.send = true;
                  this.timer = setInterval(() => {
                    if (this.time <= 0) {
                      this.codeFlag = true;
                      this.send = false;
                      clearInterval(this.timer);
                    } else {
                      this.time--;
                    }
                  }, 1000);
                } else {
                  this.codeFlag = true;
                  this.$ylmessage.error(res.msg);
                }
              })
              .catch(() => {
                this.codeFlag = true;
              });
          }
        } else {
          this.$ylmessage.error("请输入邮箱");
        }
      }
    },
    toRetrieve() {
      this.$router.push({ path: "/retrieve" });
    },
  },
};
</script>

<style lang="scss" scoped>
.yl-input {
  display: flex;
  align-items: center;
  // position: relative;
  margin-bottom: 10px;
  border: 1px solid #f8f5f1;
  height: 40px;
  background-color: #fff;
  input {
    display: block;
    border: none;
    background: none;
    outline: none;
    width: 100%;
    height: 100%;
    // text-indent: 40px;
    font-size: 14px;
  }
  input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #b1b1b1;
  }
  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #b1b1b1;
  }
  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b1b1b1;
  }
  .icon {
    flex-shrink: 0;
    position: relative;
    // left: 0;
    // top: 0;
    width: 40px;
    height: 40px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      max-height: 50%;
      max-width: 50%;
    }
  }
  .code-btn {
    cursor: pointer;
    flex-grow: 1;
    flex-shrink: 0;
    width: 110px;
    height: 25px;
    margin-right: 10px;
    font-size: 12px;
    border: none;
    border-radius: 13px;
    background-color: #49b67b;
    color: #fff;
  }
  .code-btn.active {
    background-color: #999;
    color: #f1f1f1;
  }
  .forget {
    cursor: pointer;
    flex-grow: 1;
    width: 130px;
    margin-right: 10px;
    font-size: 12px;
    color: #1872bb;
  }
}
.disabled,
input.disabled {
  cursor: not-allowed;
  background-color: #f2f2f2;
  // color: #333;
}
</style>
